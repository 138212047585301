<template>
  <div>

    <app-step step="1" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-hand-holding-box"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Datos de envío</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">

      <b-alert v-if="show_alert == 'show'" :show="show_alert" variant="danger">
        <p class="mb-0">Favor de rellenar los siguientes elementos:</p>
        <p class="mb-0" v-for="(item, key) in invalid_elements" :key="key">{{ item }}</p>
      </b-alert>
      
      <div class="row d-flex justify-content-between">
        <b-form class="container">
          
          <div class="row px-3">
            <div class="col-sm-6 mb-2 px-2">
              <b-form-input
                v-model="firstname"
                placeholder="Nombre (s)"
              ></b-form-input>
            </div>
            <div class="col-sm-6 mb-2 px-2">
              <b-form-input
                v-model="lastname"
                placeholder="Apellido (s)"
              ></b-form-input>
            </div>
          </div>

          <div class="row px-3">
            <div class="col-sm-4 mb-2 px-2">
              <b-form-input
                v-model="address"
                placeholder="Dirección de entrega"
              ></b-form-input>
            </div>
            <div class="col-sm-4 mb-2 px-2">
              <b-form-input
                v-model="city"
                placeholder="Colonia"
              ></b-form-input>
            </div>
            <div class="col-sm-4 mb-2 px-2">
              <b-form-input
                v-model="postcode"
                placeholder="Código Postal"
              ></b-form-input>
            </div>
          </div>

          <div class="row px-3">
            <div class="col-sm-6 mb-2 px-2">
              <b-form-select 
                id="selectZone"
                v-model="zone"
                :options="zones"
              ></b-form-select>
            </div>
            <div class="col-sm-6 mb-2 px-2">
              <b-form-select 
                id="selectCountry"
                v-model="country"
                :options="countries"
                v-on:change="get_zones"
              ></b-form-select>
            </div>
          </div>

          <div class="row px-3">
            <div class="col-12 px-2">
              <b-form-textarea
                v-model="comentaries"
                placeholder="Comentarios"
                rows="2"
                max-rows="4"
              ></b-form-textarea>
            </div>
          </div>

        </b-form>
      </div>

      <div class="row d-flex justify-content-right mt-5 px-2">
        <div class="col-sm-3 ml-auto">
          <b-button v-on:click="go_to(1)" variant="white" block>Requiero Factura</b-button>
        </div>
        <div class="col-sm-4">
          <b-button v-on:click="go_to(2)" variant="primary" block>Siguiente</b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import countriesList from '../assets/countries.json'
import zonesList from '../assets/zones.json'

export default {
  name: 'Shipping',
  data() {
    return {
      options: '',

      firstname: '',
      lastname: '',
      address: '',
      city: '',
      postcode: '',
      country: '',
      country_id: '',
      zone: '',
      zone_id: '',
      comentaries: '',

      show_alert: '',
      invalid_elements: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    go_to(id) {
      if(this.validate()) 
      {
        var shipping = this.$store.getters.get_shipping
        
        shipping.data = { 
          firstname: this.firstname,
          lastname: this.lastname,
          company: this.firstname,
          address: this.address,
          city: this.city,
          postcode: this.postcode,
          country: this.country.country,
          country_id: this.country.country_id,
          zone: this.zone.zone,
          zone_id: this.zone.zone_id,
          comment: this.comentaries,
        }
        this.$store.commit('set_shipping', shipping)

        switch (id) {
          case 1: this.$router.push('/invoice'); break;
          case 2: this.$router.push('/payment'); break;
        }
      } else this.show_alert = 'show'
    },
    validate() {
      this.invalid_elements = []
      if(this.fisrtname == '' || this.firstname == null) this.invalid_elements.push('Nombre (s)')
      if(this.lastname == '' || this.lastname == null) this.invalid_elements.push('Apellido (s)')
      if(this.address == '' || this.address == null) this.invalid_elements.push('Dirección de entrega')
      if(this.city == '' || this.city == null) this.invalid_elements.push('Colonia')
      if(this.zone == '' || this.zone == null) this.invalid_elements.push('Estado')
      if(this.country == '' || this.country == null) this.invalid_elements.push('País')

      if(this.invalid_elements.length == 0) return true
      return false
    },
    get_zones() {
      var country_id = this.country.country_id
      var zones = zonesList
      for (const key in zones) {
        if (Object.hasOwnProperty.call(zones, key)) {
          const element = zones[key];
          if(element.value.country_id == country_id) this.zones.push(element)
        }
      }
    }
  },
  created() {
    this.firstname = ''
    this.lastname = ''
    this.company = ''
    this.address = ''
    this.city = ''
    this.postcode = ''
    this.country = { country: 'Mexico', country_id: 138 }
    this.country_id = 138
    this.zone = { zone: 'Jalisco', zone_id: 2158, country_id: 138 }
    this.zone_id = 2158
    this.comentaries = ''

    this.countries = countriesList
    this.zones = []

    this.invalid_elements = []

    this.get_zones()
  },
}
</script>